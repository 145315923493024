import { RegistrationClient } from "@/generated/user/registration_grpc_web_pb.js";
import wrappers from "google-protobuf/google/protobuf/wrappers_pb";

import { fetchMethodData } from '@/utils/loaders.js';
import { extractResponseTokens } from './tokens.js';


const REGISTRATION_SERVICE = new RegistrationClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL,
  null,
  null
);

function refresh(refreshToken) {
  return new Promise((resolve, reject) => {
    const request = new wrappers.StringValue();
    request.setValue(refreshToken.token);
    REGISTRATION_SERVICE.refreshTokens(request, null, (err, response) => {
      if (err) {
        reject(err);
      } else {
        const tokens = extractResponseTokens(response)
        resolve({ ...tokens, isRegistered: true });
      }
    });
  });
}

async function registerGuest() {
  const request = new proto.google.protobuf.Empty();
  const createGuestResponse = await fetchMethodData(
    REGISTRATION_SERVICE, 
    'createGuest', 
    {request}
  );
  const guestId = createGuestResponse.getValue();
  return guestId
}

async function registerUserStart(email) {
  const request = new proto.kazatel.user.LiteRegistrationInfo();
  request.setMail(email);

  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    'createLite', 
    {request}
  );
  return { code: response.getCode(), hash: response.getHash() }
}

async function registerUserCodeConfirmation(hashedEmail, confirmationCode, reset = false) {
  const regInfo = new proto.kazatel.user.LiteRegistrationInfo();
  regInfo.setMail(hashedEmail);

  const request = new proto.kazatel.user.VerificationCode();
  request.setHash(regInfo);
  request.setCode(confirmationCode);

  let registerMethod = 'verifyLiteRegistration';
  if (reset) {
    registerMethod = 'verifyRestoreAccess';
  }
  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    registerMethod, 
    {request}
  );
  const tokens = extractResponseTokens(response);
  return { ...tokens, isRegistered: true }
}

async function registerUserPasswordSetup(password, accessToken) {
  const request = new wrappers.StringValue();
  request.setValue(password);

  const metadata = { 'token': accessToken.token };

  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    "changePassword", 
    {request, metadata}
  );
  const tokens = extractResponseTokens(response);
  return { ...tokens, isRegistered: true }
}

async function loginGuest(guestId) {
  const request = new proto.google.protobuf.StringValue();
  request.setValue(guestId);

  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    'loginGuest', 
    {request}
  );
  const tokens = extractResponseTokens(response);
  return { ...tokens, isRegistered: false }
}

async function loginUser(email, password, source, loginInSource) {
  const registrationInfo = new proto.kazatel.user.LiteRegistrationInfo();
  registrationInfo.setMail(email);
  const request = new proto.kazatel.user.AuthorizationParams();
  request.setRegistrationInfo(registrationInfo);
  request.setPass(password);
  request.setSource(source);
  request.setLoginInSource(loginInSource);

  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    'login', 
    {request}
  );
  const tokens = extractResponseTokens(response);
  return { ...tokens, isRegistered: true }
}

async function restoreUserAccess(email) {
  const request = new proto.kazatel.user.LiteRegistrationInfo();
  request.setMail(email);
  
  const response = await fetchMethodData(
    REGISTRATION_SERVICE, 
    'restoreAccess', 
    {request}
  );
  return { code: response.getCode(), hash: response.getHash() }
}


export {
  refresh,
  registerGuest,
  registerUserStart,
  registerUserCodeConfirmation,
  registerUserPasswordSetup,
  loginGuest,
  loginUser,
  restoreUserAccess
}